import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from 'apollo-link-ws'
import { from, split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient } from 'apollo-client'
import { mockResolvers } from '@/utils/graphql'
import { onError } from 'apollo-link-error'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { GQL_URL_AINSTEIN, GQL_WS_URL as wsEndpoint, GQL_URL_EUMONICS, EUMONICS_TOKEN } from '@/constants/app'

Vue.use(VueApollo)

const notify = (title, text) => {
  Vue.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title,
      icon: 'mdiAlert',
      variant: 'danger',
      text
    }
  })
}

function createApolloClient({ getAuth, httpEndpoint, wsEndpoint, clientId }) {

  let wsClient

  const cache = new InMemoryCache()

  if (typeof window !== 'undefined') {
    const state = window.__APOLLO_STATE__
    if (state && state[clientId]) {
      cache.restore(state[clientId])
    }
  }

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        notify(`GQL Request Error`, message)
        // eslint-disable-next-line no-console
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}, Location:`, locations)
      })
    }
    if (networkError) {
      notify(`GQL Network Error`, networkError.message)
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}`)
    }
  })

  const httpLink = createUploadLink({
    uri: httpEndpoint
  })

  const authLink = setContext(async (_, { headers }) => {
    const Authorization = await getAuth()
    const authorizationHeader = Authorization ? { Authorization } : {}
    return {
      headers: {
        ...headers,
        ...authorizationHeader
      }
    }
  })

  let link = from([
    onErrorLink,
    authLink,
    httpLink
  ])

  if (wsEndpoint) {
    wsClient = new SubscriptionClient(wsEndpoint, {
      reconnect: true,
      lazy: true, // to not connect without request
      connectionParams: () => {
        const Authorization = getAuth()
        return Authorization ? { Authorization, headers: { Authorization } } : {}
      }
    })

    const wsLink = new WebSocketLink(wsClient)

    link = split(arg => {
      const { query } = arg
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' &&
					(definition.operation === 'subscription' || arg.getContext().throughWS /* for send via WS */)
    },
    wsLink,
    link)
  }

  const apolloClient = new ApolloClient({
    link,
    cache,
    typeDefs: require('@graphQL/mocks.graphql'),
    resolvers: mockResolvers
  })

  apolloClient.wsClient = wsClient

  return apolloClient
}

export const APOLLO_CLIENTS = {
  // will be filled in createProvider
}

export const createProvider = store => {
  APOLLO_CLIENTS.AINSTEIN = createApolloClient({
    getAuth() {
      return `Token ${store.state.user.token}`
    },
    httpEndpoint: GQL_URL_AINSTEIN,
    wsEndpoint,
    clientId: 'Ainstein'
  })
  APOLLO_CLIENTS.EUMONICS = createApolloClient({
    getAuth() {
      return `Token ${EUMONICS_TOKEN}`
    },
    httpEndpoint: GQL_URL_EUMONICS,
    clientId: 'Eumonics'
  })
  return new VueApollo({
    clients: APOLLO_CLIENTS
  })
}

// Manually call this when user log in
export function onLogin() {
  return Promise.all(Object.values(APOLLO_CLIENTS).map(client => {
    const { wsClient } = client
    wsClient && wsClient.close(true)
    return client.resetStore()
  })).catch(e => {
    console.error('%cError on cache reset (login)', 'color: orange;', e.message)
  })
}

// Manually call this when user log out
export const onLogout = onLogin
