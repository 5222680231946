import { Ability, AbilityBuilder } from '@casl/ability'
import { ROUTE_NAMES } from '@/constants/routes'
import { ACCESS_MODE } from '@/constants/app'

const ADMINS = (process.env.VUE_APP_ADMINS || '').split(',').map(item => item.trim()).filter(item => Boolean(item))

const abilities = new Ability()
const { can, rules, cannot } = new AbilityBuilder(Ability)

const resetAbilities = () => {
  const count = rules.length
  for (let x = 0; x < count; x++) {
    rules.pop()
  }
}

const guestAbility = () => {
  if(ACCESS_MODE){
    can('view', ROUTE_NAMES.LOGIN)
    can('view', ROUTE_NAMES.ERROR_404)
  }
  else {
    can('manage', 'all')
    cannot('view', ROUTE_NAMES.ADMIN_ROOT)
    cannot('view', ROUTE_NAMES.ADMIN_PBI)
  }
}

const userAbility = (user = {}) => {
  // TODO: must stop using manage all into future
  can('manage', 'all')

  const { sub } = user
  const isAdmin = ADMINS.includes(sub)

  cannot('view', ROUTE_NAMES.LOGIN)
  !isAdmin && cannot('view', ROUTE_NAMES.ADMIN_ROOT)
  !isAdmin && cannot('view', ROUTE_NAMES.ADMIN_PBI)
}

export const defineAbilitiesFor = user => {
  resetAbilities()

  can('view', '403')
  can('view', '404')
  can('view', 'callbacks.google')

  user?.sub ? userAbility(user) : guestAbility(user)

  abilities.update(rules)
}

// Initial ability
defineAbilitiesFor()

export default abilities
