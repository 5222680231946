import portfolioModule from './portfolioModule'
import etfModule from './etfModule'
import pbiReports from './pbiReports'

export {
  portfolioModule,
  pbiReports,
  etfModule
}

export default {
  portfolioModule,
  pbiReports,
  etfModule
}
