import routes from '@/router/routes'
import cloneDeep from 'lodash/cloneDeep'

const findRoute = (name, arr) => {
  let route
  arr.forEach(el => {
    if (!route) {
      if (el.name === name) {
        route = el
      }
      else if (el.children) {
        route = findRoute(name, el.children)
      }
    }
  })
  return route
}

export const injectRequiredProps = item => {
  const route = item.route ? findRoute(item.route, routes) : null
  if (route?.requiredProps) {
    item.requiredProps = cloneDeep(route.requiredProps)
  }
  item.children && item.children.forEach(injectRequiredProps)
  return item
}

