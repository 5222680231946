import PAGES from '@/router/routes/pages'
import ADMIN from '@/router/routes/admin'
import { useState } from '@/store/use'
import { ROUTE_NAMES } from '@/constants/routes'
import { DEFAULT_SEARCH_CONTEXT } from '@/constants/search'

const imports = []
if(process.env.PORTFOLIO){
  imports.push(require('./portfolio').default)
}
if(process.env.COMPANY){
  imports.push(require('./company').default)
}
if(process.env.ETF){
  imports.push(require('./etf').default)
}
if(process.env.EARNINGS_ENGINE){
  imports.push(require('./earnings-engine').default)
}
if(process.env.FORECASTS){
  imports.push(require('./forecasts').default)
}
if(process.env.INDUSTRIES){
  imports.push(require('./industries').default)
}
if(process.env.EXCHANGES){
  imports.push(require('./exchanges').default)
}
if(process.env.MARKETS){
  imports.push(require('./markets').default)
}
if(process.env.SMART_NEWS){
  imports.push(require('./smart-news').default)
}
if(process.env.MUTUAL_FUNDS){
  imports.push(require('./mutual-funds').default)
}
if(process.env.ESG){
  imports.push(require('./esg').default)
}
if(process.env.BONDS){
  imports.push(require('./bonds').default)
}
if(process.env.COMPANIES){
  imports.push(require('./companies').default)
}
if(process.env.EUMONICS){
  imports.push(require('./eumonics').default)
}

const modules = imports.flat()
export const ADMIN_ROUTES = ADMIN

const HOME_PAGE = modules[0].children ? modules[0].children[0] : modules[0]
const HOME_PAGE_ROUTE = {
  path: '/',
  redirect: () => {
    const state = useState('search')
    const context = HOME_PAGE.meta?.context || DEFAULT_SEARCH_CONTEXT
    const { storeKey, paramKey } = context
    const name = HOME_PAGE.name
    return { name, params: {
      [paramKey]: state[storeKey]
    } }
  }
}

const DEFAULT_PAGES = [
  HOME_PAGE_ROUTE,
  {
    path: '*',
    redirect: ROUTE_NAMES.ERROR_404
  }
]
export default [
  ...DEFAULT_PAGES,
  ...PAGES,
  ...ADMIN,
  ...modules
]
