<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    v-on="$listeners">
    <path
      fill-rule="evenodd"
      fill="currentColor"
      :d="d"/>
  </svg>
</template>

<script>
  import icons from './icons'

  export default {
    name: 'Mdicon',

    props: {
      icon: {
        type: String,
        required: true
      },
      color: {
        type: String,
        default: null
      },
      size: {
        type: [String, Number],
        default: 20
      }
    },

    computed: {
      d() {
        return icons[this.icon] || icons.default
      }
    }
  }
</script>
