import config from '../../config.yml'

let envConf
try {
  envConf = require(`../../config.${process.env.NODE_ENV}.yml`).default
}
catch (e) {
  // no thrown needed.
}

const mergeDeep = (target, ...sources) => {
  const isObject = item => {
    return (item && typeof item === 'object' && !Array.isArray(item))
  }

  if (!sources.length) {
    return target
  }
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} })
        }
        mergeDeep(target[key], source[key])
      }
      else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

if (envConf) {
  mergeDeep(config, envConf)
}

export default config
