import axios from '@axios'
import { PORTFOLIO_DATA_URL, RATINGS_URL, STOCKS_URL } from '@/constants/app'
import { CUBE_URL } from '@/constants/cubes'
import { PORTFOLIO_TYPES } from '@/constants/portfolios'

export default {
  namespaced: true,
  state: {
    companiesData: {},
    portfolios: {},
    loading: false,
    maxBubbleSize: 90,
    etfMap: {}
  },
  getters: {},
  mutations: {
    setCompanies(state, { data, code }) {
      state.companiesData = { ...state.companiesData, [code]: data }
    },
    setPortfolio(state, { data, id }) {
      state.portfolios = { ...state.portfolios, [id]: data }
    },
    setLoading(state, status) {
      state.loading = status
    },
    setMaxBubbleSize(state, value) {
      state.maxBubbleSize = value
    },
    setETFInfo(state, { ticker, value }) {
      const { etfMap } = state
      etfMap[ticker] = value
      state.etfMap = { ...etfMap }
    }
  },
  actions: {
    async loadTickers(_, { id, cubeType }) {
      const url = `https://ainsteinbackend-dev.azurewebsites.net/API/v1/ETFFamilyCube/${ id }?cubeType=${ cubeType }`
      const { data: { data } } = await axios.get(url)
      data.sort((a, b) => Number(a.AUM) < Number(b.AUM) ? 1 : -1)
      return data
    },
    async loadCompanyData({ state, commit }, ticker) {
      const code = ticker.toLowerCase()
      if (code && !state.companiesData[code]) {
        try {
          commit('setCompanies', { data: { loading: true }, code })
          const { data } = await axios.get(`${ RATINGS_URL }${ code }`)
          commit('setCompanies', { data, code })
        }
        catch (e) {
          commit('setCompanies', { data: {}, code })
        }
      }
    },
    async loadPortfolioData({ state, commit }, id) {
      if (id && !state.portfolios[id]) {
        commit('setLoading', true)
        try {
          const { data } = await axios.get(`${ PORTFOLIO_DATA_URL.replace(':id', id) }?portfolioType=${ PORTFOLIO_TYPES.ETF }`)
          commit('setPortfolio', { data, id })
        }
        catch (e) {
          commit('setPortfolio', { data: {}, id })
        }
        finally {
          commit('setLoading', false)
        }
      }
    },
    loadSeries(_, { ticker, series }) {
      return new Promise(resolve => {
        axios.get(`${ STOCKS_URL }${ ticker }/data/${ series }`)
          .then(({ data }) => {
            resolve({ ticker, series, data })
          })
          .catch(function (error) {
            resolve(error)
          })
      })
    },
    getETFInfo({ commit }, ticker) {
      return new Promise(resolve => {
        axios.get(`${ CUBE_URL }/api/v1/ETFInfo/${ ticker }`).then(({ data: value }) => {
          commit('setETFInfo', { ticker, value })
        }).catch(resolve)
      })
    }
  }
}
