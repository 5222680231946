import store from '@/store'
import { computed, onUnmounted } from '@vue/composition-api'

export const useState = module => module ? store.state[module] : store.state
export const useGetters = module => {
  module = module || ''
  return Object.keys(store.getters).filter(key => key.indexOf(module) === 0).reduce((map, key) => {
    const index = key.lastIndexOf('/')
    map[key.slice(index !== -1 ? index + 1 : 0)] = computed(() => store.getters[key])
    return map
  }, {})
}
export const useMutation = module => module ? (name, val) => store.commit(`${module}/${name}`, val) : (name, val) => store.commit(name, val)
export const useAction = module => module ? (name, val) => store.dispatch(`${module}/${name}`, val) : (name, val) => store.dispatch(name, val)
export const registerModule = (name, module) => !store.hasModule(name) && store.registerModule(name, module)
export const unregisterModule = name => store.hasModule(name) && store.unregisterModule(name)
export const useStore = module => ({
  state: useState(module),
  getters: useGetters(module),
  dispatch: useAction(module),
  mutation: useMutation(module)
})
export const useStoreComponent = (moduleName, module) => {
  registerModule(moduleName, module)
  onUnmounted(() => unregisterModule(moduleName))
  return useStore(moduleName)
}
export const switchStoreComponent = (prevModuleName, newModuleName, module) => {
  prevModuleName && unregisterModule(prevModuleName)
  return useStoreComponent(newModuleName, module)
}
