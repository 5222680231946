import { reset, stages } from '@/utils/store'
import { LOADING, CREATING, UPDATING, DESTROYING } from '@/constants/stages'
import useApollo from '@core/hooks/apollo'

const defaultState = () => ({
  ...stages.STATE(),
  reportLinks: []
})

export default {
  namespaced: true,
  state: {
    ...defaultState()
  },
  getters: {
    ...stages.GETTERS,
    isReportExists({ reportLinks }) {
      return code => {
        return reportLinks.some(({ sectionCode }) => sectionCode === code)
      }
    }
  },
  mutations: {
    ...stages.MUTATIONS,
    setReportLinks(state, reportLinks) {
      state.reportLinks = reportLinks
    },
    ...reset.MUTATIONS(defaultState)
  },
  actions: {
    async reloadReportLinks({ commit }) {
      commit('setStage', LOADING)
      try {
        const { ainstein } = useApollo()
        const { data: { map } } = await ainstein.reload('Ainstein/PBI/pbiReportLinks')
        return commit('setReportLinks', map)
      }
      finally {
        commit('resetStage')
      }
    },
    async createLink({ commit }, variables) {
      commit('setStage', CREATING)
      try {
        const { ainstein } = useApollo()
        await ainstein.send('Ainstein/PBI/pbiReportLinkCreate', variables)
      }
      finally {
        commit('resetStage')
      }
    },
    async updateLink({ commit }, variables) {
      commit('setStage', UPDATING)
      try {
        const { ainstein } = useApollo()
        await ainstein.send('Ainstein/PBI/pbiReportLinkUpdate', variables)
      }
      finally {
        commit('resetStage')
      }
    },
    async removeLink({ commit }, variables) {
      commit('setStage', DESTROYING)
      try {
        const { ainstein } = useApollo()
        await ainstein.send('Ainstein/PBI/pbiReportLinkDelete', variables)
      }
      finally {
        commit('resetStage')
      }
    }
  }
}
