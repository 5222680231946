import { load, reload, send } from '@/utils/apollo'
import { AINSTEIN, EUMONICS } from '@/constants/apollo'

export default function useApollo() {
  return {
    ainstein: {
      load: load.bind(null, AINSTEIN),
      reload: reload.bind(null, AINSTEIN),
      send: send.bind(null, AINSTEIN)
    },
    eumonics: {
      load: load.bind(null, EUMONICS),
      reload: reload.bind(null, EUMONICS),
      send: send.bind(null, EUMONICS)
    }
  }
}
