import axios from '@axios'
import { INDUSTRY_URL } from '@/constants/app'

export const STATE = {
  industries: [],
  industryMap: {},
  industriesLoading: false,
  industryLoading: false
}

export const ACTIONS = {
  async getIndustries({ commit }) {
    commit('setIndustriesLoading', true)
    try {
      const { data } = await axios.get(INDUSTRY_URL)
      commit('setIndustries', data)
      return data
    }
    finally {
      commit('setIndustriesLoading', false)
    }
  },
  async getIndustry({ commit }, id) {
    commit('setIndustryLoading', true)
    try {
      const { data } = await axios.get(`${INDUSTRY_URL}/${id}`)
      commit('setIndustry', { id, data })
      return data
    }
    finally {
      commit('setIndustryLoading', false)
    }
  }
}

export const MUTATIONS = {
  setIndustries(state, industries) {
    state.industries = industries
  },
  setIndustry(state, { id, data }) {
    state.industryMap[id] = data
  },
  setIndustriesLoading(state, bool) {
    state.industriesLoading = bool
  },
  setIndustryLoading(state, bool) {
    state.industryLoading = bool
  }
}
