const AINSTEIN_COLORS = Object.freeze([
  '#a03484',
  '#e43648',
  '#e69736',
  '#439e91',
  '#70be5c',
  '#03afee'
])

const RATING_COLORS = Object.freeze({
  undefined: '#b7b7b7',
  A: '#81CB92',
  B: '#A1D600',
  C: '#E8E834',
  D: '#E89313',
  F: '#D14836'
})

// do not use ES Modules export notation, only CommonJS
module.exports = {
  AINSTEIN_COLORS,
  RATING_COLORS
}
