import config from '@/config'

export const CONTEXT_KEY_COMPANIES = 'companies'
export const CONTEXT_KEY_PORTFOLIOS = 'portfolios'
export const CONTEXT_KEY_ETF = 'etf'

export const DEFAULT_SEARCH_COMPANY = config.search_data.default.company
export const DEFAULT_SEARCH_PORTFOLIO = config.search_data.default.portfolio
export const DEFAULT_SEARCH_ETF = config.search_data.default.etf

/**
 *
 * @value:string Key for taking data from the store
 * @title:string Label for the currently selected search item
 * @key:string key for taking data in search
 */
export const CONTEXTS = {
  TICKER: {
    storeKey: 'ticker',
    paramKey: 'ticker', // route params key
    mutationName: 'setTicker',
    defaultValue: DEFAULT_SEARCH_COMPANY,
    title: 'Company',
    key: CONTEXT_KEY_COMPANIES
  },
  PORTFOLIO: {
    storeKey: 'portfolioId',
    paramKey: 'portfolioId', // route params key
    mutationName: 'setPortfolioId',
    defaultValue: DEFAULT_SEARCH_PORTFOLIO,
    title: 'Portfolio',
    key: CONTEXT_KEY_PORTFOLIOS
  },
  ETF: {
    storeKey: 'etfPortfolioId',
    paramKey: 'portfolioId', // route params key
    mutationName: 'setEtfPortfolioId',
    defaultValue: DEFAULT_SEARCH_ETF,
    title: 'Portfolio',
    key: CONTEXT_KEY_ETF
  }
}

export const DEFAULT_SEARCH_CONTEXT = CONTEXTS.PORTFOLIO

// Search
export const COMPANIES = config.search_data.companies
export const PORTFOLIOS = config.search_data.portfolios

export const SEARCH_DATA = {
  [CONTEXT_KEY_PORTFOLIOS]: {
    keys: ['label'],
    data: [],
    get isEmpty() {
      return !this.data.length
    },
    setData(data) {
      this.data = data.map(this.parse)
    },
    parse: ({ title, id, label }) => ({
      groupKey: CONTEXT_KEY_PORTFOLIOS,
      id: `${id}`,
      label: `Portfolio: ${label || title || id}`
    })
  },
  [CONTEXT_KEY_COMPANIES]: {
    keys: ['label'],
    data: [],
    get isEmpty() {
      return !this.data.length
    },
    setData(data) {
      this.data = data.map(this.parse)
    },
    parse: ({ code, title, id, label }) => ({
      groupKey: CONTEXT_KEY_COMPANIES,
      id: `${code || id}`,
      label: `${(code || id).toUpperCase()} - ${label || title || id}`
    })
  },
  [CONTEXT_KEY_ETF]: {
    keys: ['label'],
    data: [],
    get isEmpty() {
      return !this.data.length
    },
    setData(data) {
      this.data = data.map(this.parse)
    },
    parse: ({ id = '', ticker = '', name = '' }) => ({
      groupKey: CONTEXT_KEY_ETF,
      id: `${id || ticker}`,
      label: ticker ? `${ticker} - ${name}` : `${id}`
    })
  }
}
