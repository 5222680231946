import axios from '@axios'
import { API, PORTFOLIO_DATA_URL, RATINGS_URL, STOCKS_URL } from '@/constants/app'
import { PORTFOLIO_TYPES } from '@/constants/portfolios'
import { industry, subIndustry, sector } from '@/utils/store'

export default {
  namespaced: true,
  state: {
    ...industry.STATE,
    ...subIndustry.STATE,
    ...sector.STATE,
    companiesData: {},
    portfolios: {},
    loading: false,
    maxBubbleSize: 90
  },
  getters: {},
  mutations: {
    ...industry.MUTATIONS,
    ...subIndustry.MUTATIONS,
    ...sector.MUTATIONS,
    setCompanies(state, { data, code }) {
      state.companiesData = { ...state.companiesData, [code]: data }
    },
    setPortfolio(state, { data, id }) {
      state.portfolios = { ...state.portfolios, [id]: data }
    },
    setLoading(state, status) {
      state.loading = status
    },
    setMaxBubbleSize(state, value) {
      state.maxBubbleSize = value
    }
  },
  actions: {
    ...industry.ACTIONS,
    ...subIndustry.ACTIONS,
    ...sector.ACTIONS,
    async loadTickers(_, params) {
      const { data } = await axios.get(`${API}${ params.join('/') }`)
      data.sort((a, b) => Number(a.marketcap) < Number(b.marketcap) ? 1 : -1)
      return data
    },
    async loadCompanyData({ state, commit }, ticker) {
      const code = ticker.toLowerCase()
      if (code && !state.companiesData[code]) {
        try {
          commit('setCompanies', { data: { loading: true }, code })
          const { data } = await axios.get(`${ RATINGS_URL }${ code }`)
          commit('setCompanies', { data, code })
        }
        catch (e) {
          commit('setCompanies', { data: {}, code })
        }
      }
    },
    async loadPortfolioData({ state, commit }, id) {
      if (id && !state.portfolios[id]) {
        commit('setLoading', true)
        try {
          const { data } = await axios.get(`${ PORTFOLIO_DATA_URL.replace(':id', id) }?portfolioType=${PORTFOLIO_TYPES.PORTFOLIO}`)
          commit('setPortfolio', { data, id })
        }
        catch (e) {
          commit('setPortfolio', { data: {}, id })
        }
        finally {
          commit('setLoading', false)
        }
      }
    },
    loadSeries(_, { ticker, series }) {
      return new Promise(resolve => {
        axios.get(`${ STOCKS_URL }${ ticker }/data/${ series }`)
          .then(({ data }) => {
            resolve({ ticker, series, data })
          })
          .catch(function (error) {
            resolve(error)
          })
      })
    }
  }
}
