import { ROUTE_NAMES } from '@/constants/routes'

export default [
  {
    path: '/error-404',
    name: ROUTE_NAMES.ERROR_404,
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/login',
    name: ROUTE_NAMES.LOGIN,
    component: () => import('@/views/pages/authentication/Login'),
    meta: {
      layout: 'full'
    }
  }
]
