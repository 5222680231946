import config from '@/config'

// Power BI
export const POWER_BI_COMPANY_URL = `${config.api}powerbi`
export const POWER_BI_PORTFOLIO_URL = `${config.api}powerbip`

// API
export const API = config.api

// Authentication
export const SSO_CLIENT_ID = config.sso.clientId
export const SSO_URL = config.sso.sso_url
export const REDIRECT_URI = config.sso.redirect_uri
export const AUTH_URL = config.sso.auth_url

// Eumonics
export const EUMONICS_URL = config.eumonics.url
export const EUMONICS_URL_TA = `${config.eumonics.base_url }series-viewer/`
export const EUMONICS_TOKEN = config.eumonics.token

// Ratings
export const RATINGS_URL = `${config.api }ratings/`

// Ribbon Screens
export const RIBBON_SCREENS_URL = `${config.api }ribbon-screens`

// Stocks
export const STOCKS_URL = `${config.ain_api }stocks/`

// Industry
export const INDUSTRY_URL = `${config.ain_api }industries`

// Subindustry
export const SUBINDUSTRY_URL = `${config.ain_api }subindustries`

// Sector
export const SECTOR_URL = `${config.ain_api }sectors`

// Portfolio
export const PORTFOLIO_URL = `${ config.ain_api }portfolios`

// Portfolio Data
export const PORTFOLIO_DATA_URL = `${ config.api }portfolio-data/:id`

export const GQL_URL_AINSTEIN = config.graphql.ainstein
export const GQL_URL_EUMONICS = config.graphql.eumonics
export const GQL_WS_URL = config.graphql.ws_url

// Access Mode
export const ACCESS_MODE = config.access_mode
