import isNil from 'lodash/isNil'

const sum = arr => {
  let len = arr.length
  let num = 0
  while (len--) {
    num += Number(arr[len])
  }
  return num
}

const avg = (arr, idx, range) => {
  return sum(arr.slice(idx - range, idx)) / range
}

const pdiff = (firstValue, nextVal) => {
  const result = firstValue === 0 || !firstValue || !nextVal ? null : ((nextVal - firstValue)/firstValue * 100)
  return firstValue < 0 ? -result : result
}

const sub = (firstValue, nextValue) => {
  return !firstValue || !nextValue ? null : nextValue - firstValue
}

export const SMA = (arr, range, format) => {
  if (!Array.isArray(arr)) {
    throw TypeError('expected first argument to be an array')
  }

  const num = range || arr.length
  const res = []
  const len = arr.length + 1
  let idx = num - 1
  while (++idx < len) {
    res.push(Number((avg(arr, idx, num)).toFixed(format)))
  }
  return res
}

export const percentageDifference = values => {
  if (!Array.isArray(values)) {
    throw TypeError('expected first argument to be an array')
  }

  const res = []
  let firstValue = null

  for (let idx = 0; idx < values.length; idx++) {
    if (!firstValue && values[idx]) {
      firstValue = values[idx]
    }
    res.push((pdiff(firstValue,  values[idx])))
  }
  return res
}

export const substraction = values => {
  if (!Array.isArray(values)) {
    throw TypeError('expected first argument to be an array')
  }

  const res = []
  let firstValue = null

  const len = values.length
  for (let idx = 0; idx < len; idx++) {
    if (!firstValue && values[idx]) {
      firstValue = values[idx]
    }
    res.push((sub(firstValue, values[idx])))
  }
  return res
}
