import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import routes from './routes'
import useAuth from '@hooks/auth'
import { useState, useMutation, useAction } from '@/store/use'
import { DEFAULT_SEARCH_CONTEXT } from '@/constants/search'
import { ROUTE_NAMES } from '@/constants/routes'
import { SEARCH, APP_CONFIG } from '@/constants/store'
import { initialization } from '@core/hooks'
import { ACCESS_MODE } from '@/constants/app'

// Browser
import { browser } from '@/utils'

// eslint-disable-next-line no-duplicate-imports
export * as routes from './routes'

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, _, next) => {
  const { isUserAuthenticated } = useAuth()
  const authenticated = await isUserAuthenticated()

  if (!authenticated && to.name !== ROUTE_NAMES.LOGIN && ACCESS_MODE) {
    return next({ name: ROUTE_NAMES.LOGIN })
  }
  else if (authenticated && to.name === ROUTE_NAMES.LOGIN) {
    return next({ path: '/' })
  }

  if(!ACCESS_MODE) {
    const { initialization: { initialized } } = useState(APP_CONFIG)
    if (!initialized) {
      const { useInitialization } = initialization
      const { initialize } = useInitialization()
      await initialize()
    }
  }

  if (!canNavigate(to)) {
    return next({ name: ROUTE_NAMES.ERROR_404 })
  }

  return next()
})

router.beforeEach((to, _, next) => {
  const dispatch = useAction(SEARCH)
  const commit = useMutation(SEARCH)
  const { meta: { context = DEFAULT_SEARCH_CONTEXT } = {} } = to

  const { storeKey, paramKey, mutationName } = context
  const key = paramKey || storeKey
  if (key in to.params) {
    commit(mutationName, to.params[key].toString())
  }

  dispatch('setContext', context)

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.afterEach(to => {
  const matched = to.matched.filter(el => el.name).map(el => el.name).join(': ')
  document.title = browser.tabTitle(matched)
})

export default router
