import { APP_CONFIG, PBI_REPORTS } from '@/constants/store'
import { registerModule, useStore } from '@/store/use'
import { computed } from '@vue/composition-api'
import { pbiReports } from '@/store/modules'

export function useInitialization() {

  registerModule(PBI_REPORTS, pbiReports)
  const { dispatch } = useStore(PBI_REPORTS)
  const { mutation, state } = useStore(APP_CONFIG)

  const initialized = computed({
    get() {
      return state.initialization.initialized
    },
    set(val) {
      mutation('setInitialized', val)
    }
  })

  const reloadReportLinks = () => {
    return dispatch('reloadReportLinks')
  }

  const initialize = async () => {
    if (initialized.value) {
      return initialized.value
    }
    await reloadReportLinks()
    // eslint-disable-next-line require-atomic-updates
    initialized.value = true
    return initialized.value
  }

  return {
    initialize
  }
}
