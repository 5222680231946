const mutations = {}

mutations.setToken = (state, val) => {
  state.token = val
  val ? localStorage.setItem('access_token', val): localStorage.removeItem('access_token')
}

mutations.setAuthenticated = (state, val) => {
  state.authenticated = val
}

mutations.setData = (state, val) => {
  state.data = val
}

export default mutations
