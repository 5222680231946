import {
  DEFAULT_SEARCH_CONTEXT,
  SEARCH_DATA,
  CONTEXT_KEY_ETF,
  CONTEXT_KEY_PORTFOLIOS,
  CONTEXT_KEY_COMPANIES,
  PORTFOLIOS, COMPANIES,
  CONTEXTS
} from '@/constants/search'
import useApollo from '@core/hooks/apollo'

export default {
  namespaced: true,
  state: {
    ...Object.keys(CONTEXTS).reduce((state, key) => {
      state[CONTEXTS[key].storeKey] = CONTEXTS[key].defaultValue
      return state
    }, {}),
    context: DEFAULT_SEARCH_CONTEXT,
    searchDataSource: SEARCH_DATA
  },
  getters: {
    searchData({ context, searchDataSource }) {
      const { key } = context
      const { data } = searchDataSource[key] // to keep reactivity
      return {
        [key]: { ...searchDataSource[key], data }
      }
    },
    isDataLoading({ context, searchDataSource }) {
      const { key } = context
      return searchDataSource[key].isEmpty
    }
  },
  mutations: {
    ...Object.keys(CONTEXTS).reduce((state, key) => {
      const { storeKey } = CONTEXTS[key]
      state[CONTEXTS[key].mutationName] = (state, val) => {
        state[storeKey] = val
      }
      return state
    }, {}),
    setContext(state, context) {
      state.context = context
    },
    updateSearchDataSource({ searchDataSource, context: { key } }, data) {
      searchDataSource[key].setData(data)
    }
  },
  actions: {
    setContext({ commit, dispatch }, context) {
      commit('setContext', context)
      return dispatch('loadSearchVariants')
    },
    async loadSearchVariants({ commit, state: { searchDataSource, context: { key } } }) {
      if (!searchDataSource[key].isEmpty) {
        return
      }
      switch (key) {
      case CONTEXT_KEY_COMPANIES: {
        commit('updateSearchDataSource', COMPANIES)
        break
      }
      case CONTEXT_KEY_PORTFOLIOS: {
        commit('updateSearchDataSource', PORTFOLIOS)
        break
      }
      case CONTEXT_KEY_ETF:
      {
        try {
          const { ainstein } = useApollo()
          const { data: { etfs } } = await ainstein.reload('Ainstein/etfListSearch')
          commit('updateSearchDataSource', etfs)
        }
        catch (e) {
          console.error(e)
        }
      }
      }
    }
  }
}
