import uniqid from 'uniqid'
import pkceChallenge from 'pkce-challenge'
import { AUTH_URL, REDIRECT_URI, SSO_CLIENT_ID, SSO_URL } from '@/constants/app'
import { computed } from '@vue/composition-api'
import axios from 'axios'
import router from '@/router'
import toast from '@/libs/toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useState, useMutation } from '@/store/use'
import { onLogin, onLogout } from '@/libs/vue-apollo'
import { defineAbilitiesFor } from '@/libs/acl/ability'
import { APP_CONFIG } from '@/constants/store'
import { initialization } from '@core/hooks'

export default function useAuth() {
  const userState = useState('user')
  const commit = useMutation('user')

  const initialize = async () => {
    const { initialization: { initialized } } = useState(APP_CONFIG)
    if (!initialized) {
      const { useInitialization } = initialization
      const { initialize } = useInitialization()
      await initialize()
    }
  }

  const authenticated = computed({
    get() {
      return userState.authenticated
    },
    set(val) {
      commit('setAuthenticated', val)
      if (!val) {
        return onLogout()
      }
    }
  })
  const token = computed({
    get() {
      return userState.token
    },
    set(val) {
      commit('setToken', val)
    }
  })
  const userData = computed({
    get() {
      return userState.data
    },
    set(val) {
      commit('setData', val)
    }
  })

  const authFusion = () => {
    const url = new URL(`${ SSO_URL }authorize`)
    const { 'code_challenge': challenge, 'code_verifier': verify } = pkceChallenge()
    const state = uniqid()
    url.searchParams.set('client_id', SSO_CLIENT_ID)
    url.searchParams.set('response_type', 'code')
    url.searchParams.set('redirect_uri', REDIRECT_URI)
    url.searchParams.set('scope', 'offline_access test openid')
    url.searchParams.set('state', state)
    url.searchParams.set('code_challenge', challenge)
    url.searchParams.set('code_challenge_method', 'S256')

    sessionStorage.setItem('state', state)
    sessionStorage.setItem('verify', verify)

    window.location = url
  }

  const saveUserData = token => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return `%${ (`00${ c.charCodeAt(0).toString(16)}`).slice(-2)}`
    }).join(''))

    const data = JSON.parse(jsonPayload)
    userData.value = data
    return data
  }

  const auth = async code => {
    const { data } = await axios.get(AUTH_URL, {
      params: {
        code,
        redirect_uri: REDIRECT_URI,
        code_verifier: sessionStorage.getItem('verify')
      }
    })

    sessionStorage.removeItem('verify')
    return data.accessToken
  }

  const applyUser = async () => {
    const user = saveUserData(token.value)
    defineAbilitiesFor(user)
    authenticated.value = true
    await onLogin()
    await initialize()
  }


  const login = async () => {
    if (token.value) {
      return applyUser()
    }

    const query = new URL(window.location).searchParams
    const code = query.get('code')
    const stateTruth = query.get('state') === sessionStorage.getItem('state')

    if (code && stateTruth) {
      sessionStorage.removeItem('state')
      try {
        token.value = await auth(code)
        await applyUser()
        router.push({ path: '/' })
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Welcome ${ userData.value.preferred_username || '' }`,
            icon: 'mdiHumanGreetingVariant',
            variant: 'success',
            text: 'Nice to meet you again'
          }
        })
      }
      catch (e) {
        authenticated.value = false
      }
      return
    }

    authenticated.value = false
  }

  const isUserAuthenticated = async () => {
    if (authenticated.value === null) {
      await login()
    }
    return authenticated.value
  }

  const logout = () => {
    token.value = null
    const url = new URL(`${ SSO_URL }logout`)
    url.searchParams.set('client_id', SSO_CLIENT_ID)
    window.location = url
  }

  return {
    logout,
    isUserAuthenticated,
    authFusion,
    authenticated
  }
}
